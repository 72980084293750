import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  Formik, Form, Field, ErrorMessage
} from 'formik'

import Button from './button'
import Loader from '../images/ajax-loader.gif'

const Wrapper = styled.div`
  flex: 1;

  input[type="text"],
  input[type="textarea"],
  input[type="email"] {
    width: 100%;
    max-width: 350px;
    padding: 24px;
    border: thin solid lightgray;
    border-radius: 40px;
  }

  input[type="textarea"] {
    min-height: 200px;
  }

  p {
    margin-top: 16px;
  }
`

const Label = styled.label`
  display: block;
  margin-top: 12px;

  a {
    text-decoration: none;
    color: var(--deep-red);
  }
`

const SentWrapper = styled.div`
  display: flex;
  margin-top: 18px;
  align-items: center;
`

const SendButton = styled(Button)`
  margin-right: 12px;
  font-weight: bold;
`

function Kontakt () {
  const [wasSuccessful, setWasSuccessful] = useState(null)

  return (
    <Wrapper>
      <Formik
        initialValues={{
          name: '',
          email: '',
          telefon: '',
          betreff: '',
          nachricht: '',
          datenschuetz: false
        }}
        validate={(values) => {
          const errors = {}
          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          // eslint-disable-next-line no-undef
          const response = await fetch(
            `https://europe-west1-sb-mailer.cloudfunctions.net/submitForm/WkgpWh7ucR4n6Tcvxz2u`,
            {
              method: 'post',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(values)
            }
          )
          const result = await response.json()
          if (result.msg === 'SUCCESS') {
            setWasSuccessful(true)
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <p>Ihr Name*</p>
            <Field type="text" name="name" />
            <ErrorMessage name="name" component="div" />

            <p>Ihre E-Mail-Adresse*</p>
            <Field type="email" name="email" />
            <ErrorMessage name="email" component="div" />

            <p>Ihre Telefonnummer</p>
            <Field type="text" name="telefon" />
            <ErrorMessage name="telefon" component="div" />

            <p>Betreff</p>
            <Field type="text" name="betreff" />
            <ErrorMessage name="betreff" component="div" />

            <p>Ihre Nachricht</p>
            <Field type="textarea" name="nachricht" />
            <ErrorMessage name="nachricht" component="div" />

            <Label>
              <Field type="checkbox" name="datenschuetz" required />
              Ich habe die{' '}
              <Link to="/datenschutzerklaerungen">
                Datenschutzerklärung
              </Link>{' '}
              zur Kenntnis genommen. Ich stimme zu, dass meine Angaben zur
              Kontaktaufnahme und für Rückfragen dauerhaft gespeichert werden.
            </Label>
            <ErrorMessage name="datenschuetz" component="div" />

            <SentWrapper>
              <SendButton type="submit" disabled={isSubmitting}>
                SENDEN
              </SendButton>

              {isSubmitting && <img src={Loader} alt="Loading..." />}
              {wasSuccessful && <span>Ihre Nachricht wurde gesendet.</span>}
            </SentWrapper>

            <p>*Felder sind Pflichtfelder</p>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default Kontakt
