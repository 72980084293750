/* eslint-disable max-len */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../components/layout'
import ContactForm from '../components/kontakt'
import SEO from '../components/seo'

const Header = styled.div`
  position: relative;
  width: 100vw;
  max-height: 400px;
  margin: 0 auto 128px auto;
  z-index: -1;

  img {
    filter: brightness(0.6) contrast(0.76);
    max-height: 400px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 124px;
  }
`

const TitleBox = styled.div`
  position: absolute;
  top: 5%;
  display: flex;
  flex-direction: column;
  height: 95%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 4.5em;
    word-break: break-word;

    @media only screen and (max-width: 768px) {
      font-size: 3.5rem;
    }

    @media only screen and (max-width: 500px) {
      font-size: 2rem;
    }
  }

  h1,
  p {
    color: white;
  }
`

const Image = styled.img`
  padding: 12px;
  height: auto;
  max-width: 50%;
  border-radius: 32px;
`

const Bold = styled.p`
  font-weight: bold;
  color: var(--heading-text);
`

const Hyperlink = styled.a`
  text-decoration: none;
  // font-weight: bold;
  color: var(--deep-red);
`

const Embed = styled.div`
  .blank-space {
    width: 100%;
    height: 32px;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: ${props =>
    (props.direction === true ? 'row' : 'row-reverse')};

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    img {
      margin: auto;
      max-width: 100%;
    }
  }
`

const TextBlock = styled.div`
  display: block;
  padding: 0 12px;
  line-height: 1.4rem;
`

const KontaktContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Content = styled.div`
  flex: 1;
`

const StyledLink = styled(Link)`
  text-decoration: none;

  :hover {
    color: white;
  }

  ::after {
    content: " | ";
    color: white;
  }
`

const Paragraph = styled.p`
  margin: 18px auto;
`

const Video = styled.iframe`
  width: 100%;
`

const Text = ({ children }) => <Paragraph>{children}</Paragraph>

const ImageTag = ({ children }) => (
  <Image src={children.file['en-US'].url} alt={children.description['en-US']} />
)

const OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [INLINES.HYPERLINK]: children =>
      (children.data.uri.match(/youtu\.?be/g) ? (
        <Video
          title={children.content[0].value}
          width="1120"
          height="630"
          src={`https://www.youtube.com/embed/${children.data.uri
            .split('/')
            .pop()}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : (
        <Hyperlink href={children.data.uri}>
          {children.content[0].value}
        </Hyperlink>
      )),
    [INLINES.ASSET_HYPERLINK]: children => (
      <Hyperlink href={children.data.target.fields.file['en-US'].url}>
        {children.content[0].value}
      </Hyperlink>
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <ImageTag>{node.data.target.fields}</ImageTag>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => (
      <Embed>
        <Flex direction={node.data.target.fields.mediaSide['en-US'] || false}>
          <ImageTag>{node.data.target.fields.media['en-US'].fields}</ImageTag>
          <TextBlock>
            {documentToReactComponents(
              node.data.target.fields.body['en-US'],
              OPTIONS
            )}
          </TextBlock>
        </Flex>
        <div className="blank-space" />
      </Embed>
    )
  }
}

export default ({ pageContext }) => (
  <div>
    <SEO
      title={pageContext.title}
      keywords={['bodytunes', 'ems', 'ems-training', 'ems-studio', 'fitness']}
    />
    {pageContext.headerImage && (
      <Header>
        <Img fluid={pageContext.headerImage.fluid} alt="Header Image" />
        <TitleBox>
          <h1>{pageContext.title.toUpperCase()}</h1>
          <p>
            <StyledLink to="/">Home</StyledLink>
            {pageContext.title}
          </p>
        </TitleBox>
      </Header>
    )}

    <Layout>
      <KontaktContainer>
        <Content>
          {documentToReactComponents(
            pageContext.childContentfulBasicPageBodyRichTextNode.json,
            OPTIONS
          )}
        </Content>

        {pageContext.title === 'Kontakt' ? <ContactForm /> : <></>}
      </KontaktContainer>
    </Layout>
  </div>
)
