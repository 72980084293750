import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background: var(--deep-red);
  padding: 14px 32px;
  border: 3px solid var(--deep-red);
  border-radius: 100px;
  font-family: Oswald;
  font-size: 1.1em;
  letter-spacing: 0.09em;
  color: white;
  cursor: pointer;
  transition: background 240ms, color 240ms;

  :hover {
    background: white;
    color: var(--deep-red);
  }
`

export default Button
